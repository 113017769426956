const getEnv = () => {
    if (window.location.host === "www.ide.builder.ai") {
        return "prod"
    } else if (window.location.host === "www.stage.ide.builder.ai") {
        return "stg"
    } else {
        return "dev"
    }
}

const getBrowser = () => {
    const { userAgent } = navigator;

    if (userAgent.includes('Firefox/')) {
        return 'Firefox';
    } else if (userAgent.includes('Edg/')) {
        return 'Edge';
    } else if (userAgent.includes('Chrome/')) {
        return "Chrome";
    } else if (userAgent.includes('Safari/')) {
        return "Safari";
    }

    // Return userAgent for unsupported browsers
    return userAgent;
}

export {
    getEnv,
    getBrowser
}