import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/main.scss';

import { getEnv } from './utils';
import { baseURL, sentryEndpoint } from "../src/config";


const currentEnv = getEnv();
if (currentEnv !== "dev") {
    Sentry.init({
        dsn: sentryEndpoint,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: currentEnv,
    });
}


ReactDOM.render(
    <React.Fragment>
        <App baseURL={baseURL}/>
    </React.Fragment>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
