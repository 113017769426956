import React, { lazy, Suspense } from "react";
import "./App.css";
import { Utils } from "./js/Utils";
import Logger from "js-logger";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
const Homepage = lazy(() => import("./Components/HomePage/HomePage"));
const Error404 = lazy(() => import("./Components/Errors/Error404"));
const Error404Deallocated = lazy(() =>
  import("./Components/Errors/Error404Deallocated")
);
const Error404Default = lazy(() =>
  import("./Components/Errors/Error404Default")
);
const Error505 = lazy(() => import("./Components/Errors/Error505"));

Utils.loadConfig();

function App(params) {
  Logger.useDefaults();
  if (process.env.NODE_ENV === "production") {
    Logger.setLevel(Logger.OFF);
  }
  window.Logger = Logger;

  return (
    <div className="wrapper">
      <Suspense fallback={""}>
        <Router>
          <Switch>
            <Route path="/error404" exact component={Error404} />
            <Route
              path="/error404deallocated"
              exact
              component={Error404Deallocated}
            />
            <Route path="/error404default" exact component={Error404Default} />
            <Route path="/error505" exact component={Error505} />
            <Route
              path="/:id"
              exact
              component={() => <Homepage baseURL={params.baseURL} />}
            />
            <Redirect from="*" to="/error404default" />
          </Switch>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
