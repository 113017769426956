const baseURL = "__MARKER_FOR_BACKEND_URL_REPLACEMENT";
const sentryEndpoint = "https://0b27d242cf254982b1c9b7d68645b0da@o1107930.ingest.sentry.io/6204533";

const faceAuthCredentials = {
    dev: {
        username:  "FaceAuth",
        password: "8cdd2dcd432835b85ed9d5e4976fd0ec1fe700e2f07bd4463eb9892b3adb0fea",
    },
    stg: {
        username:  "FaceAuth",
        password: "8cdd2dcd432835b85ed9d5e4976fd0ec1fe700e2f07bd4463eb9892b3adb0fea",
    },
    prod: {
        username:  "FaceAuth",
        password: "ef4493cdd200271f4269a5074c458599ea9fb144eea61cf00ca22db73d750911",
    },
}

export {
    baseURL,
    sentryEndpoint,
    faceAuthCredentials,
};
